import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { ListAutoHide } from "../components/ListAutoHide";
import ContactForm from "../components/contact";

const p1 = [
  {
    txt: (
      <p>
        <span>
          {" "}
          Kiedy planujemy modernizację naszej placówki warto zacząć od naszego
          internetu, dlaczego? Bo w dzisiejszej dobie wszelkie urządzenia będą
          go wykorzystywać. Jak więc zacząć?
        </span>{" "}
        <br />{" "}
        <span>
          Wykonujemy bezkosztowy projekt oraz wycenę. Po wcześniejszej wizji
          lokalnej jesteśmy w stanie zasugerować Państwu najlepsze rozwiązania,
          które zapewnią bezpieczne oraz wydajne wykorzystanie internetu na
          terenie całego biura a nawet całego obiektu. Nasza{" "}
          <strong>współpraca z najlepszymi markami i dystrybutorami</strong>{" "}
          pozwoli na wejście w posiadanie najnowocześniejszych urządzeń takich
          jak: UTM-y, switche, routery, koncentratory, acces pointy itp.{" "}
        </span>
        <span>
          Zwracamy uwagę na optymalne rozwiązania i modernizację systemów tak,
          by przy stosunkowo niedużym wkładzie finansowym zwiększyć wydajność
          Państwa urządzeń, co bezpośrednio wpływa na komfort pracy.
        </span>
      </p>
    ),
  },
];
const p2 = [
  {
    txt: (
      <p>
        <span>
          Cenią sobie Państwo czas, przy jednoczesnej redukcji kosztów?{" "}
        </span>{" "}
        <span>
          <b>Oferujemy najnowocześniejsze rozwiązania digitalizacji</b>, które
          ułatwią zarządzanie dokumentacją oraz szybki dostęp do informacji o
          transakcjach. Ułatwia to nie tylko codzienną pracę, dzięki segregacji
          i uporządkowania dokumentów, ale również pozwala na oszczędność
          powierzchni biurowej.
        </span>
        Bezpieczne przechowywanie danych skutecznie eliminuje bałagan w
        szufladach. Pomożemy Państwu wdrożyć nowoczesne rozwiązania, które
        podniosą opinie o Państwa firmie – digitalizacja to nowoczesność i dobre
        zorganizowanie.
      </p>
    ),
  },
];
const p3 = [
  {
    txt: (
      <p>
        <span>
          {" "}
          Podpis kwalifikowany ma bardzo szerokie zastosowania, które są
          regularnie poszerzane.{" "}
        </span>{" "}
        <span>
          {" "}
          Staje się tak między innymi poprzez kolejne wdrażane w polskim prawie
          ustawy,{" "}
          <b>
            wymagające od coraz większej grupy użytkowników, w tym
            przedsiębiorców, uwierzytelniania podpisem elektronicznym.
          </b>
        </span>
        <span>
          {" "}
          Już teraz jest obligatoryjnym narzędziem podczas wysyłania sprawozdań
          do KRS, składania deklaracji ZUS, udziału w aukcjach na
          elektronicznych platformach przetargowych oraz w branży aptekarskiej
          do przekazywania danych o obrocie produktów leczniczych do Głównego
          Inspektora Farmaceutycznego.
        </span>
        <Link to="/podpis-kwalifikowany">
          <button className="btn-red">Dowiedz się więcej</button>
        </Link>
      </p>
    ),
  },
];
const p4 = [
  {
    txt: (
      <p>
        <span>
          Planują Państwo zakup kasy fiskalnej nie wiedząc na jaki produkt się
          zdecydować?
        </span>{" "}
        <span>
          <b>Posiadamy kompetencje i wieloletnie doświadczenie</b>, które
          pozwoli nam dobrać Państwu odpowiednie urządzenie. Wdrażamy
          rozwiązania dla różnych branż takich jak: sklepy, placówki handlowe,
          restauracje, salony fryzjerskie, biura, kancelarie, warsztaty
          samochodowe i wiele innych. Doradzamy, programujemy, szkolimy,
          serwisujemy.
        </span>{" "}
        <span>
          {" "}
          Jak każde urządzenie elektroniczne, kasy fiskalne wymagają regularnego
          serwisowania – gwarantuje to dłuższą żywotność i niezawodność. Nie
          zostawiamy Państwa bez opieki.
        </span>
      </p>
    ),
  },
];
const p5 = [
  {
    txt: (
      <p>
        <span>
          Co w przypadku, gdy potrzebujemy dużego, wydajnego urządzenia A3, ale
          cena zakupu wydaje się dla nas zbyt wysoka?{" "}
          <span>Na to też mamy rozwiązanie!</span>
        </span>{" "}
        <br />
        Dzierżawa kserokopiarki polega na darmowym wdrożeniu urządzenia w
        dowolnym miejscu, skonfigurowaniu oraz przeszkoleniu z jego obsługi.{" "}
        <br />
        <span>
          <b>
            ⇨ wdrożeniu ciągu chwili otrzymują Państwo gotowe do pracy
            urządzenie{" "}
          </b>
        </span>{" "}
        <span>
          <b>⇨ zdalne przekazywanie informacji o błędach i licznikach.</b>
        </span>
        <span>
          <b>
            ⇨ Wypożyczamy nowe, nowoczesne drukarki i urządzenia wielofunkcyjne
            A4 pozwalające na tanią, wydajną pracę w każdym miejscu
          </b>
        </span>{" "}
        Na samym początku powinniśmy odpowiedzieć sobie na kilka pytań:
        <br /> • ile kopii w ciągu jednego miesiąca jesteśmy w stanie wykonywać?
        <br /> • czy będziemy potrzebowali druku w kolorze, czy wystarczy nam
        druk monochromatyczny? <br />• czy drukować będziemy w niestandardowych
        formatach oraz niestandardowym papierze?
        <br /> • jak istotne jest dla nas tempo oraz jakość druku?
        <br /> • czy urządzenie powinno posiadać automatyczny dwustronny
        podajnik?
        <span>
          Odpowiedzi na powyższe pytania w znaczący sposób pomogą nam dobrać
          odpowiednie urządzenie. A jest w czym wybierać - dysponujemy szerokim
          wachlarzem modeli najbardziej popularnych producentów na Świecie
          takich jak: <b>RICOH, BROTHER, CANON, EPSON, HP, OKI.</b>
        </span>
      </p>
    ),
  },
];
const p6 = [
  {
    txt: (
      <p>
        <span>Specjalizujemy się w zapewnieniu Państwu ciągłości pracy.</span>
        <span>
          Zaopatrujemy w części eksploatacyjne drukarek jak również w tusze,
          tonery oraz różnego formatu papier. W swojej ofercie posiadamy
          oryginalne tusze i tonery, a także najwyższej jakości zamienniki,
          pozwalające na redukcję kosztów przy jednoczesnym zachowaniu dobrej
          jakości wydruków.{" "}
        </span>
        <span>
          <b>
            Naszymi Klientami są instytucje, szkoły, małe i duże firmy, Klienci
            prywatni oraz Partnerzy handlowi.{" "}
          </b>
        </span>
        <span>
          Wysyłamy nasze produkty za pośrednictwem firmy kurierskiej, a także za
          pomocą własnego transportu firmowego – co pozwala na szybką reakcję i
          dostawę oraz wdrożenie, jeśli tylko zachodzi taka potrzeba.
        </span>
      </p>
    ),
  },
];

const pageData = {
  header: "Biznes & Instytucja",
  mainTitle: "Kompleksowa obsługa",
  subtitle: "w jednym miejscu",
};

const OfferBusinessPage = () => {
  const [active, setActive] = useState("active");
  useEffect(() => {
    window.addEventListener("load", function () {
      return setActive("active");
    });
  }, []);
  return (
    <>
      <div className={`offer-business-page-info ${active}`}>
        <PageInfo
          header={pageData.header}
          mainTitle={pageData.mainTitle}
          // date={pageData.date}
          subtitle={pageData.subtitle}
        />
      </div>
      <Layout>
        <section className="offer-business-page">
          <div className="buttons">
            <Link to="#audio-video">
              {" "}
              <button className="btn-red">audio-video</button>
            </Link>
            <Link to="#infrastructure">
              <button className="btn-red">infrastruktura IT</button>
            </Link>
            <Link to="#remote">
              <button className="btn-red">praca zdalna</button>
            </Link>
            <Link to="#officeStaff">
              <button className="btn-red">wyposażenie biura</button>
            </Link>
            <Link to="#services">
              <button className="btn-red">usługi</button>
            </Link>
          </div>

          <div className="offer-element">
            <div id="audio-video" className="anchor"></div>
            <div className="advantages-container offer">
              <div className="advantages txt">
                <div className="txt-container">
                  <h4>Zaufaj profesjonalistom</h4>
                  <h2>
                    Rozwiązania <br /> <strong>audio-video</strong>
                  </h2>
                </div>
                <p className="headerTxt">
                  Zajmiemy się obsługą Twojej firmy, abyś mógł się skupić na
                  swoim biznesie
                </p>
              </div>
              <div className="advantages-container-wrapper">
                <div className="advantages-container-wrapper-box">
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/offer-adv/wydajnosc3.svg")
                          .default
                      }
                    />
                    <h3>zwiększ</h3>
                    <p>Wydajność</p>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="żarówka"
                      src={
                        require("../assets/images/offer-adv/efektywnosc2.svg")
                          .default
                      }
                    />

                    <h3>popraw</h3>
                    <p>Efektywność</p>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="samolot z papieru"
                      src={
                        require("../assets/images/offer-adv/wizerunek.svg")
                          .default
                      }
                    />

                    <h3>zadbaj o</h3>
                    <p>wizerunek</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="table">
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    wyświetlanie <br /> obrazu i interaktywna <br /> współpraca
                  </h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ monitory interaktywne i wyświetlające</p>
                  <p>⇨ projektory i ekrany</p>
                  <p>⇨ ściany wielkoformatowe LCD i LED</p>
                  <p>⇨ systemy audio</p>
                  <p>
                    ⇨ współdzielenie treści i współpraca w czasie rzeczywistym
                  </p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    systemy <br /> nagłośnienia <br /> i dystrybucji
                  </h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ głośniki sufitowe, naścienne, kierunkowe, mikrofony</p>
                  <p>⇨ systemy dyskusyjne i do głosowania, panele</p>
                  <p>⇨ systemy akustyczne, wspomagania słysznia</p>
                  <p>⇨ pętle indukcyjne, panele dotykowe</p>
                  <p>⇨ transmisja i zarządzanie sygnałem</p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>videokonferencje</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ kamery, głośniki, mikrofony, soundbary</p>
                  <p>⇨ kompleksowe rozwiązania</p>
                </div>
              </div>
            </div>
            <StaticImage
              className="offer-img"
              src="../assets/images/businessOffer/business.jpg"
              alt=""
            />
          </div>

          <div className="offer-element">
            <div id="infrastructure" className="anchor"></div>
            <div className="advantages-container offer">
              <div className="advantages txt">
                <div className="txt-container">
                  <h4>Nowoczesna i kompleksowa</h4>
                  <h2>Infrastruktura sieciowa</h2>
                </div>
                <p className="headerTxt">
                  Stwórz z nami przyszłość <strong>Twojego biznesu!</strong>
                </p>
              </div>
              <div className="advantages-container-wrapper">
                <div className="advantages-container-wrapper-box">
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/offer-adv/bezpieczenstwo2.svg")
                          .default
                      }
                    />
                    <p>Bezpieczeństwo</p>
                    <span>
                      Oferujemy rozwiązania, które zapewniają{" "}
                      <strong>pełne bezpieczeństwo</strong> sieci
                    </span>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="samolot z papieru"
                      src={
                        require("../assets/images/offer-adv/wydajnosc2.svg")
                          .default
                      }
                    />

                    <p>Wydajność</p>
                    <span>
                      Nie ma znaczenia czy potrzebujesz przewodowej czy
                      bezprzewodowej sieci - nasze rozwiązania są{" "}
                      <strong> stabilne i niezawodne</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="table">
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    systemy <br /> monitoringu budynków
                  </h2>
                </div>
                <div className="table-container-wrapper">
                  <p>
                    ⇨ <b>optymalna i bezpieczna</b> <br />
                    sieć informatyczna
                  </p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    systemy <br /> telewizji dozorowej
                  </h2>
                </div>
                <div className="table-container-wrapper">
                  <p>
                    ⇨ <b>bezpieczna architektura</b> <br />
                    systemowo-serwerowa
                  </p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>systemy alarmowe</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>
                    ⇨ przechowywanie <br /> <b>i backup danych</b>
                  </p>
                </div>
              </div>
            </div>
            <StaticImage
              className="offer-img"
              src="../assets/images/businessOffer/business1.jpg"
              alt=""
            />
          </div>

          <div className="offer-element">
            <div id="remote" className="anchor"></div>
            <div className="advantages-container offer">
              <div className="advantages txt">
                <div className="txt-container">
                  <h4>Niezawodność i funkcjonalność</h4>
                  <h2>Praca zdalna</h2>
                </div>
                <p className="headerTxt">
                  Nowoczesne rozwiązania informatyczne, które{" "}
                  <b>ułatwią Ci pracę!</b>
                </p>
              </div>
              <div className="advantages-container-wrapper">
                <div className="advantages-container-wrapper-box">
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/offer-adv/koszty.svg").default
                      }
                    />
                    <h3>redukuj</h3>
                    <p>koszty</p>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="samolot z papieru"
                      src={
                        require("../assets/images/offer-adv/produktywnosc.svg")
                          .default
                      }
                    />

                    <h3>zwiększ</h3>
                    <p>produktywność</p>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="żarówka"
                      src={
                        require("../assets/images/offer-adv/komfort.svg")
                          .default
                      }
                    />

                    <h3>zyskaj</h3>
                    <p>komfort</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="table">
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    notebooki <br /> switche i routery
                  </h2>
                </div>
                <div className="table-container-wrapper">
                  <p>
                    ⇨ <b>optymalna i bezpieczna</b> <br />
                    sieć informatyczna
                  </p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    drukarki <br /> urządzenia wielofunkcyjne
                  </h2>
                </div>
                <div className="table-container-wrapper">
                  <p>
                    ⇨ <b>bezpieczna architektura</b> <br />
                    systemowo-serwerowa
                  </p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>sieć VPN</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>
                    ⇨ przechowywanie <br /> <b>i backup danych</b>
                  </p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>rozwiązania chmurowe</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>
                    ⇨ przechowywanie <br /> <b>i backup danych</b>
                  </p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    akcesoria <br /> i ergonomia pracy
                  </h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ kamerki, słuchawki</p>
                  <p>⇨ podstawki chłodzące</p>
                  <p>⇨ urządzenia wskazujące</p>
                  <p>⇨ podkładki, uchwyty i inne</p>
                </div>
              </div>
            </div>
            <StaticImage
              className="offer-img"
              src="../assets/images/businessOffer/business4.jpg"
              alt=""
            />
          </div>

          <div className="offer-element">
            <div id="officeStaff" className="anchor"></div>
            <div className="advantages-container offer">
              <div className="advantages txt">
                <div className="txt-container">
                  <h4>Skuteczne rozwiązania</h4>
                  <h2>Wyposażenie biura</h2>
                </div>
                <p className="headerTxt">
                  Rozwiązania, które zoptymalizują pracę <b>Twojej firmy!</b>
                </p>
              </div>
              <div className="advantages-container-wrapper">
                <div className="advantages-container-wrapper-box">
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/offer-adv/wydajnosc.svg")
                          .default
                      }
                    />
                    <h3>zwiększ</h3>
                    <p>Wydajność</p>
                    <h3>pracy</h3>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="żarówka"
                      src={
                        require("../assets/images/offer-adv/skutecznosc.svg")
                          .default
                      }
                    />

                    <h3>popraw</h3>
                    <p>Skuteczność</p>
                    <h3>działań</h3>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="samolot z papieru"
                      src={
                        require("../assets/images/offer-adv/jakosc.svg").default
                      }
                    />

                    <h3>zadbaj o</h3>
                    <p>jakość</p>
                    <h3>usług</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="table">
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    technologie <br /> zapewniające <br /> funkcjonalność pracy
                  </h2>
                </div>
                <div className="table-container-wrapper">
                  <div>
                    <p>⇨ notebooki, komputery</p>
                    <Link
                      className="prodImg"
                      target="_blank"
                      rel="noreferrer noopener "
                      to="https://jbm.pl/pl/searchquery/laptopy/1/phot/5?url=laptopy"
                    >
                      <StaticImage
                        src="https://jbm.pl/userdata/public/gfx/64531/asus.jpg"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div>
                    <p>⇨ AIO, monitory</p>
                    <Link
                      className="prodImg"
                      target="_blank"
                      rel="noreferrer noopener "
                      to="https://jbm.pl/pl/searchquery/monitory/1/phot/5?url=monitory"
                    >
                      <StaticImage
                        src="https://jbm.pl/userdata/public/gfx/48846/7878552_0_f.jpg"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div>
                    <p>⇨ drukarki, kopiarki</p>
                    <Link
                      className="prodImg"
                      target="_blank"
                      rel="noreferrer noopener "
                      to="https://jbm.pl/pl/searchquery/drukarka/1/phot/5?url=drukarka"
                    >
                      <StaticImage
                        src="https://jbm.pl/userdata/public/gfx/8980/Przechwytywanie16.jpg"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    rozwiązania <br /> gwarantujące <br /> elastyczność pracy
                  </h2>
                </div>
                <div className="table-container-wrapper">
                  <div>
                    <p>⇨ urządzenia mobilne</p>
                    <Link
                      className="prodImg"
                      target="_blank"
                      rel="noreferrer noopener "
                      to="https://jbm.pl/pl/p/SMARTPHONE-SAMSUNG-GALAXY-M23-5G-4128-GB-NIEBIESKI/15077"
                    >
                      <StaticImage
                        src="https://jbm.pl/userdata/public/gfx/61820/Smartfon-SAMSUNG-Galaxy-M23-5G-niebieski-tyl-front.jpg"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div>
                    <p>⇨ stacje dokujące</p>
                    <Link
                      className="prodImg"
                      target="_blank"
                      rel="noreferrer noopener "
                      to="https://jbm.pl/pl/p/Stacja-dokujaca-Natec-FOWLER-2-USB-C-3X-USB-3.0-HDMI-4K-RJ45-USB-C-SD-MICRO-SD/3258"
                    >
                      <StaticImage
                        src="https://jbm.pl/userdata/public/gfx/10002/Przechwytywanie-1.png"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div>
                    <p>⇨ tablety</p>
                    <Link
                      className="prodImg"
                      target="_blank"
                      rel="noreferrer noopener "
                      to="https://jbm.pl/pl/searchquery/tablety/1/phot/5?url=tablety"
                    >
                      <StaticImage
                        src="https://jbm.pl/userdata/public/gfx/61911/12.9_cellular_space_gray_dqwe-wd.jpg"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>sprzęty poprawiające ergonomię pracy</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ podnóżki</p>
                  <p>⇨ podstawki</p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>gwarantowane zasilanie</h2>
                </div>
                <div className="table-container-wrapper">
                  <div>
                    <p>⇨ UPSy</p>
                    <Link
                      className="prodImg"
                      target="_blank"
                      rel="noreferrer noopener "
                      to="https://jbm.pl/pl/p/GT-UPS-GT-S-11-UPS-2000VA1800W-4-X-IEC-10A-ON-LINE-TOWER/15052"
                    >
                      <StaticImage
                        src="https://jbm.pl/userdata/public/gfx/65956/GT-UPS-GT-S-11-UPS-2000VA1800W-4-IEC-10A-ON-LINE-TOWER_1182481_1200.jpg"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div>
                    <p>⇨ agregaty</p>
                    <Link
                      className="prodImg"
                      target="_blank"
                      rel="noreferrer noopener "
                      to="https://jbm.pl/pl/p/Agregat-pradotworczy-Pezal-PGG3100X/14605"
                    >
                      <StaticImage
                        src="https://jbm.pl/userdata/public/gfx/62565/AGREGAT-PRADOTWORCZY-PEZAL-PGG3100X-66847.jpg"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>artykuły biurowe</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ bindownice, laminatory</p>
                  <p>⇨ papiery, folie, strecze</p>
                  <p>⇨ itp.</p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>oprogramowanie</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ biurowe</p>
                  <p>⇨ specjalistyczne</p>
                </div>
              </div>
            </div>
            <StaticImage
              className="offer-img"
              src="../assets/images/businessOffer/business2.jpg"
              alt=""
            />
          </div>

          <div className="offer-element">
            <div id="services" className="anchor"></div>
            <div className="advantages-container offer">
              <div className="advantages txt">
                <div className="txt-container">
                  <h4>Wiedza i doświadczenie</h4>
                  <h2>Usługi</h2>
                </div>
                <p className="headerTxt">
                  Nasi Klienci mogą liczyć na fachowe wsparcie oraz indywidualne
                  podejście do każdego projektu
                </p>
              </div>
            </div>
            <div className="table services">
              <div className="table-container">
                <div className="table-header">
                  <ListAutoHide
                    header="serwis i modernizacja sprzętu i systemów"
                    paragraph={p1[0].txt}
                  />
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <ListAutoHide
                    header="digitalizacja faktur"
                    paragraph={p2[0].txt}
                  />
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <ListAutoHide
                    header="kwalifikowany podpis elektroniczny"
                    paragraph={p3[0].txt}
                  />
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <ListAutoHide
                    header="kasy i drukarki fiskalne"
                    paragraph={p4[0].txt}
                  />
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <ListAutoHide
                    header="dzierżawa druku"
                    paragraph={p5[0].txt}
                  />
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <ListAutoHide
                    header="eksploatacja druku"
                    paragraph={p6[0].txt}
                  />
                </div>
              </div>
            </div>
            <StaticImage
              className="offer-img"
              src="../assets/images/businessOffer/business3.jpg"
              alt=""
            />
          </div>
        </section>
        <div className="footer-container-wrapper-form fullImg">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
          </div>

          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => (
  <Seo title="Oferta dla biznesu i instytucji publicznych" />
);
export default OfferBusinessPage;
